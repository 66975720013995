import HttpService from './httpService'
import axios from 'axios'
import useAlert from '@/services/shared/alerts/useAlert'
import store from '@/store'
import config from '@/core/config/httpConfig'

let ignorePostRoutes = ['payments/customer', 'post-pro-plan']

export default class apiService {
  alertMessage
  httpService
  request = null

  constructor() {
    config.apiUrl = 'https://staging-api.toddl.co/api/'
    this.alertMessage = new useAlert()
    this.httpService = new HttpService(axios)
  }

  cancel() {
    if (this.request) this.request.cancel()
  }

  GET(endPoint, params = {}) {
    // To do (this causes issue in select lists)
    // this.cancel()
    let axiosSource = axios.CancelToken.source()
    this.request = { cancel: axiosSource.cancel }
    store.commit('app/UPDATE_LOADER_STATE', true)
    return new Promise((resolve, reject) => {
      this.httpService.axiosIns
        .get(config.apiUrl + endPoint, {
          params: params,
          cancelToken: axiosSource.token,
        })
        .then(response => {
          store.commit('app/UPDATE_LOADER_STATE', false)
          if (response.data.status_code === 200) {
            // this.alertMessage.alertMessage(response.data)
            return resolve(response.data.data)
          } else {
            this.alertMessage.alertMessage(response.data)
            return reject(response.data)
          }
        })
        .catch(error => {
          if (error.message !== undefined) {
            this.alertMessage.alertMessage(error)
            store.commit('app/UPDATE_LOADER_STATE', false)
          }
        })
    })
  }

  POST(endPoint, data, params = {}) {
    store.commit('app/UPDATE_LOADER_STATE', true)
    return new Promise((resolve, reject) => {
      this.httpService.axiosIns
        .post(config.apiUrl + endPoint, data, params)
        .then(response => {
          store.commit('app/UPDATE_LOADER_STATE', false)
          if (response.data.status_code === 200) {
            if (!ignorePostRoutes.includes(endPoint)) {
              this.alertMessage.alertMessage(response.data)
            }
            return resolve(response.data.data)
          } else {
            this.alertMessage.alertMessage(response.data)
            return reject(response.data)
          }
        })
        .catch(error => {
          console.log('message => ')
          store.commit('app/UPDATE_LOADER_STATE', false)
          console.log(error)
          this.alertMessage.alertMessage(error)
        })
    })
  }

  PUT(endPoint, data, params = {}) {
    store.commit('app/UPDATE_LOADER_STATE', true)
    return new Promise((resolve, reject) => {
      this.httpService.axiosIns
        .put(config.apiUrl + endPoint, data, params)
        .then(response => {
          store.commit('app/UPDATE_LOADER_STATE', false)
          if (response.data.status_code === 200) {
            this.alertMessage.alertMessage(response.data)
            return resolve(response.data.data)
          } else {
            this.alertMessage.alertMessage(response.data)
            return reject(response.data)
          }
        })
        .catch(error => {
          console.log('message => ')
          store.commit('app/UPDATE_LOADER_STATE', false)
          this.alertMessage.alertMessage(error)
        })
    })
  }

  DELETE(endPoint, params = {}) {
    store.commit('app/UPDATE_LOADER_STATE', true)
    return new Promise((resolve, reject) => {
      this.httpService.axiosIns
        .delete(config.apiUrl + endPoint, params)
        .then(response => {
          store.commit('app/UPDATE_LOADER_STATE', false)
          if (response.data.status_code === 200) {
            this.alertMessage.alertMessage(response.data)
            return resolve(response.data.data)
          } else {
            this.alertMessage.alertMessage(response.data)
            return reject(response.data)
          }
        })
        .catch(error => {
          store.commit('app/UPDATE_LOADER_STATE', false)
          this.alertMessage.alertMessage(error)
        })
    })
  }

  PATCH(endPoint, data, params = {}) {
    store.commit('app/UPDATE_LOADER_STATE', true)
    return new Promise((resolve, reject) => {
      this.httpService.axiosIns
        .patch(config.apiUrl + endPoint, data, params)
        .then(response => {
          store.commit('app/UPDATE_LOADER_STATE', false)
          if (response.data.status_code === 200) {
            this.alertMessage.alertMessage(response.data)
            return resolve(response.data.data)
          } else {
            this.alertMessage.alertMessage(response.data)
            return reject(response.data)
          }
        })
        .catch(error => {
          store.commit('app/UPDATE_LOADER_STATE', false)
          this.alertMessage.alertMessage(error)
        })
    })
  }
}
